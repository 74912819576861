<template>
    <div>
        <other-header></other-header>
        <div class="policy-detail">
            <detail-header last-name="返回" :title="info.TitleName"/>
            <div class="card">
                <div class="title">{{info.TitleName}}</div>
                <br>
                <div v-if="info.startTime" class="time">开始时间:{{info.startTime}}</div>
                <div v-if="info.endTime" class="time">结束时间:{{info.endTime}}</div>
                <div class="time">浏览：{{info.ViewTimes}}</div>
                <br>
                <div v-html="info.Details"></div>
                <br>
                <div>
                    <van-button class="submit-btn" v-if="userInfo.MemberType > 1 && info.status == 0"
                                type="info" @click="onBaoming" block>立即报名</van-button>
                
                    <van-button class="submit-btn" v-if="info.status == 1 && info.JobFairType==2" type="info"
                                @click="onJinru" block>进入会场
                    </van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// policy-detail
import DetailHeader from "@/components/detail-header";
import OtherHeader from "@/components/other-header";
export default {
    name: "jobfair-detail",
    components: {OtherHeader, DetailHeader},
    data() {
        return {
            info: {}
        }
    },
    computed: {
        id() {
            return this.$route.query.id;
        },
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    activated() {
        this.loadData();
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            // path /BaseInstitutions/GetInstitutionsInfo
            const params = {
                id: this.id
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/GetJobFairInfo`,
                params: params,
            });
            if (res.ok) {
                const item = res.data;
                // 设置状态
                let current = new Date().getTime();
                if (item.StartTime && item.EndTime) {
        
                    let start = new Date(item.StartTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                    let end = new Date(item.EndTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                    if (current < start) {
                        item.status = 0;
                    } else if (current >= start && current <= end) {
                        item.status = 1;
                    } else if (current > end) {
                        item.status = 2;
                    }
                    item.startTime = $app.dateFormatChinese(item.StartTime);
                    item.endTime = $app.dateFormatChinese(item.EndTime);
                } else if (item.StartTime && !item.EndTime) {
                    let start = new Date(item.StartTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                    if (current < start) {
                        item.status = 0;
                    } else if (current >= start) {
                        item.status = 1;
                    }
                    item.startTime = $app.dateFormatChinese(item.StartTime);
                } else if (!item.StartTime && item.EndTime) {
                    let end = new Date(item.EndTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                    if (current > end) {
                        item.status = 2;
                    } else if (current <= end) {
                        item.status = 1;
                    }
                    item.endTime = $app.dateFormatChinese(item.EndTime);
                } else if (!item.StartTime && !item.EndTime) {
                    item.status = 1;
                }
                
                this.info = item;
            }
        },
        async onBaoming() {
            // /BaseJobFair/ApplyJobFair
            const params = {
                jobFairId: this.info.Id,
                CompanyId: this.userInfo.Id
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/ApplyJobFair`,
                params: params,
                loading: '报名中...'
            });
        
            if (res.ok) {
                $app.showToast({
                    title: res.message,
                })
            }
        },
        onJinru() {
            this.$router.push(`/jobfair-detail-list?id=${this.id}`)
        }
    }
}
</script>

<style scoped lang="less">
.policy-detail {
    width: calc(22rem);
    margin: 0.4rem auto;
    & .card {
        background-color: #Fff;
        padding: 0.4rem;
        border-radius: 0.12rem;
        text-align: left;
        & .title {
            font-size: 0.8rem;
            text-align: center;
            color: #4eabe8;
            font-weight: 600;
            font-family: "Songti SC";
        }
        & .time {
            font-size: .28rem;
            color: #999999;
            line-height: .60rem;
        }
    }
}
</style>
